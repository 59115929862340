import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import logo from 'assets/images/logo.svg';

import Container from 'components/Container';
import Navigation from 'components/Navigation';
import Image from 'components/Image';
import TransLink from 'components/TransLink';

import classes from './Header.scss';
import Hamburger from './Hamburger';

function Header({ open, toggleOpen, transparent }) {
  const className = classNames({
    [classes.root]: true,
    [classes.transparent]: transparent,
    [classes.open]: open,
  });

  return (
    <div className={className}>
      <Container>
        <div className={classes.content}>
          <TransLink className={classes.link} to="/">
            <Image
              className={classes.logo}
              image={{ src: logo }}
              alt="Batao logo"
            />
          </TransLink>

          <Navigation />

          <Hamburger open={open} onClick={toggleOpen} />
        </div>
      </Container>
    </div>
  );
}

Header.propTypes = {
  transparent: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  toggleOpen: PropTypes.func.isRequired,
};

Header.defaultProps = {
  transparent: false,
};

export default Header;
