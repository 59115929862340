import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Img from 'gatsby-image';

import useImage from 'hooks/useImage';

import classes from './Image.scss';

function Image({ className: classNameProp, image: imageProp, ...props }) {
  const className = classNames(classNameProp, classes.root);
  const image = useImage(imageProp);

  if (image.sharpImage) {
    return (
      <Img
        className={className}
        fixed={image.sharpImage.fixed}
        fluid={image.sharpImage.fluid}
        {...props}
      />
    );
  }

  return (
    <img className={className} src={image.src || image.publicURL} {...props} />
  );
}

Image.propTypes = {
  image: PropTypes.shape({
    src: PropTypes.string,
    sharpImage: PropTypes.shape({
      fixed: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      fluid: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    }),
    publicURL: PropTypes.string,
  }).isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
};

Image.defaultProps = {
  className: null,
};

export default Image;
