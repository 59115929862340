import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Text from 'components/Text';
import Icon from 'components/Icon';

import classes from './Column.scss';

function Column({
  title,
  initialOpen,
  hideTablet,
  hideTitleDesktop,
  children,
}) {
  const [open, setOpen] = useState(initialOpen);

  const toggleOpen = () => setOpen(!open);

  const className = classNames({
    [classes.root]: true,
    [classes['hide-tablet']]: hideTablet,
  });

  return (
    <div className={className}>
      <button
        className={classNames({
          [classes.button]: true,
          [classes['hide-desktop']]: hideTitleDesktop,
        })}
        onClick={toggleOpen}
      >
        <Text className={classes.title} color="white" weight="semi" size="xl">
          {title}
        </Text>

        <Text
          className={classNames({
            [classes.icon]: true,
            [classes.open]: open,
          })}
          color="white"
          size="xxl"
        >
          <Icon name="caret-right" />
        </Text>
      </button>

      <div
        className={classNames({
          [classes.content]: true,
          [classes.open]: open,
        })}
      >
        {children}
      </div>
    </div>
  );
}

Column.propTypes = {
  title: PropTypes.string.isRequired,
  initialOpen: PropTypes.bool,
  hideTablet: PropTypes.bool,
  hideTitleDesktop: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Column.defaultProps = {
  initialOpen: false,
  hideTablet: false,
  hideTitleDesktop: false,
};

export default Column;
