import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Link from 'components/Link';
import Icon from 'components/Icon';

import classes from './Social.scss';

function Social({ name, href }) {
  const className = classNames({
    [classes.root]: true,
    [classes[name]]: true,
  });

  return (
    <Link as="a" href={href} className={className}>
      <Icon name={name} />
    </Link>
  );
}

Social.propTypes = {
  name: PropTypes.string.isRequired,
  href: PropTypes.string.isRequired,
};

export default Social;
