import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Icon from 'components/Icon';
import TransLink from 'components/TransLink';

import classes from './Item.scss';

function Item({ to, title, icon, badge, children }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const showInSub = !children?.find((child) => child.props.to === to);

  return (
    <div className={classes.root}>
      {children ? (
        <button className={classes.item} onClick={toggleOpen}>
          <span className={classes.title}>{title}</span>

          <Icon name="caret-right" />
        </button>
      ) : (
        <TransLink
          to={to}
          className={classes.item}
          activeClassName={classes.active}
        >
          <span className={classes.title}>
            {icon && <img className={classes.icon} src={icon} />}

            {title}

            {badge && <span className={classes.badge}>{badge}</span>}
          </span>
        </TransLink>
      )}

      {children && (
        <div
          className={classNames({
            [classes.content]: true,
            [classes.open]: open,
          })}
        >
          <button
            className={classNames(classes.item, classes.back)}
            onClick={toggleOpen}
          >
            <Icon name="arrow-left" />
            Terug
          </button>

          {showInSub && <Item to={to} title={title} icon={icon} />}

          {children}
        </div>
      )}
    </div>
  );
}

Item.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  badge: PropTypes.number,
  children: PropTypes.node,
};

Item.defaultProps = {
  icon: null,
  badge: null,
  children: null,
};

export default Item;
