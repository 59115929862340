import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Icon.scss';

function Icon({ name, flip }) {
  const className = classNames({
    [classes.root]: true,
    [classes[name]]: true,
    [classes.flip]: flip,
  });

  return <i className={className} />;
}

Icon.propTypes = {
  name: PropTypes.oneOf([
    'caret-up',
    'caret-right',
    'caret-down',
    'caret-left',
    'arrow-up',
    'arrow-right',
    'arrow-down',
    'arrow-left',
    'arrow-circle-fill',
    'arrow-circle',
    'clock',
    'facebook',
    'linkedin',
    'instagram',
    'hamburger',
    'location',
    'mail',
    'phone',
    'quote-start',
    'quote-end',
  ]).isRequired,
  flip: PropTypes.bool,
};

Icon.defaultProps = {
  flip: false,
};

export default Icon;
