import PropTypes from 'prop-types';

export default {
  size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg', 'xl', 'xxl', false]),
  weight: PropTypes.oneOf(['normal', 'semi', 'bold']),
  color: PropTypes.oneOf([
    'primary',
    'primary-light',
    'primary-lighter',
    'primary-pastel',
    'secondary',
    'secondary-pastel',
    'tertiary',
    'white',
    'gray-light',
    'gray',
    'gray-dark',
  ]),
  themeColor: PropTypes.oneOf([
    'primary',
    'primary-light',
    'secondary',
    'tertiary',
    'white',
  ]),
};
