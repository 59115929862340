import React from 'react';
import PropTypes from 'prop-types';
import AniLink from 'gatsby-plugin-transition-link/AniLink';

function TransLink({ children, ...props }) {
  return (
    <AniLink paintDrip hex="#353565" duration={0.7} {...props}>
      {children}
    </AniLink>
  );
}

TransLink.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TransLink;
