import React from 'react';

import Button from 'components/Button';
import { useStaticQuery, graphql } from 'gatsby';

import classes from './Navigation.scss';
import Item from './Item';
import Dropdown from './Dropdown';
import Column from './Column';
import DropdownItem from './DropdownItem';
import navItems from './navItems';

function Navigation() {
  const data = useStaticQuery(graphql`
    query vacancyCount {
      allVacancies {
        vacancyCount: totalCount
      }
    }
  `);

  const { vacancyCount } = data.allVacancies;
  const index = navItems.findIndex((item) => item.to === '/vacatures');

  navItems[index].badge = vacancyCount;

  return (
    <div className={classes.root}>
      {navItems.map((item) => (
        <Item key={item.to} title={item.title} to={item.to} badge={item.badge}>
          {item.items && (
            <Dropdown nested={!!item.items.some((i) => i.items?.length)}>
              {item.items.some((i) => i.items?.length)
                ? item.items.map((sub) => (
                    <Column key={sub.to} title={sub.title}>
                      {[sub, ...sub.items]?.map((columnItem) => (
                        <DropdownItem
                          key={columnItem.to}
                          to={columnItem.to}
                          title={columnItem.title}
                          info={columnItem.info}
                          icon={columnItem.icon}
                        />
                      ))}
                    </Column>
                  ))
                : item.items.map((sub) => (
                    <DropdownItem
                      key={sub.to}
                      to={sub.to}
                      title={sub.title}
                      info={sub.info}
                      icon={sub.icon}
                    />
                  ))}
            </Dropdown>
          )}
        </Item>
      ))}

      <Button color="secondary" size="sm" to="/offerte-aanvragen">
        Offerte aanvragen
      </Button>
    </div>
  );
}

export default Navigation;
