import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import StyleTypes from 'styles/types';

import classes from './Text.scss';

function Text({
  className: classNameProp,
  as: Component,
  color,
  size,
  weight,
  spacing,
  inline,
  children,
}) {
  const className = classNames(classNameProp, {
    [classes.root]: true,
    [classes[color]]: true,
    [classes[size]]: true,
    [classes[weight]]: true,
    [classes[`spacing-${spacing}`]]: spacing,
    [classes.inline]: inline,
  });

  return <Component className={className}>{children}</Component>;
}

Text.propTypes = {
  className: PropTypes.string,
  as: PropTypes.string,
  color: StyleTypes.color,
  size: StyleTypes.size,
  weight: StyleTypes.weight,
  spacing: StyleTypes.size,
  inline: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Text.defaultProps = {
  className: null,
  as: 'span',
  color: 'gray',
  size: 'md',
  weight: 'normal',
  spacing: null,
  inline: false,
};

export default Text;
