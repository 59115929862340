module.exports = {
  siteUrl: process.env.BATAO_SITE_URL || 'http://localhost:8000',
  apiUrl: process.env.API_URL || 'https://cms.batao.nl/',
  apiToken: process.env.API_TOKEN || 'fc68c6350d1ec851c3c40973638f86',
  collections: [
    {
      type: 'blog',
      options: {
        filter: { published: true },
      },
    },
    'team',
    'modules',
    'quotes',
    {
      type: 'vacancies',
      options: {
        filter: { published: true },
      },
    },
    {
      type: 'cases',
      options: {
        filter: { published: true },
      },
    },
    'slides',
    'seo',
    {
      type: 'pages',
      options: {
        filter: { published: true },
      },
    },
  ],
  company: {
    phone: '+31 85 065 6773',
    email: 'clan@batao.nl',
    address: ['Wijchenseweg 111', '6538 SW Nijmegen', 'Nederland'],
    mapsLink: 'https://goo.gl/maps/iXk7zw4RJ8WPwCPE9',
  },
  social: [
    {
      icon: 'linkedin',
      link: 'https://www.linkedin.com/company/batao-e-commerce-experts/',
    },
    {
      icon: 'facebook',
      link: 'https://www.facebook.com/BataoNijmegen',
    },
    // {
    //   icon: 'instagram',
    //   link: '',
    // },
  ],
};
