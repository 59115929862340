import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Container.scss';

function Container({
  className: classNameProp,
  size,
  spacing,
  white,
  children,
  ...props
}) {
  const className = classNames(classNameProp, {
    [classes.root]: true,
    [classes[size]]: size,
    [classes.white]: white,
    [classes.spacing]: spacing,
  });

  return (
    <div className={className} {...props}>
      {children}
    </div>
  );
}

Container.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOf(['small', 'normal', 'large']),
  spacing: PropTypes.bool,
  white: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Container.defaultProps = {
  className: null,
  size: 'normal',
  spacing: false,
  white: false,
};

export default Container;
