import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './MobileNavigation.scss';
import Item from './Item';

import navItems from '../Navigation/navItems';

function MobileNavigation({ open }) {
  const className = classNames({
    [classes.root]: true,
    [classes.open]: open,
  });

  return (
    <div className={className}>
      <div className={classes.content}>
        {navItems.map((item) => (
          <Item
            key={item.to}
            to={item.to}
            title={item.title}
            icon={item.icon}
            badge={item.badge}
          >
            {item.items?.map((sub) => (
              <Item key={sub.to} to={sub.to} title={sub.title} icon={sub.icon}>
                {sub.items?.map((subSub) => (
                  <Item
                    key={subSub.to}
                    to={subSub.to}
                    title={subSub.title}
                    icon={subSub.icon}
                  />
                ))}
              </Item>
            ))}
          </Item>
        ))}
      </div>
    </div>
  );
}

MobileNavigation.propTypes = {
  open: PropTypes.bool.isRequired,
};

export default MobileNavigation;
