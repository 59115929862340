import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StyleTypes from 'styles/types';

import Container from 'components/Container';

import classes from './Section.scss';

function Section({ color, position, pattern, shorten, children, ...props }) {
  const className = classNames({
    [classes.root]: true,
    [classes[color]]: color,
    [classes[position]]: position,
    [classes.pattern]: pattern,
    [classes.shorten]: shorten,
  });

  return (
    <div className={className} {...props}>
      <Container className={classes.container}>{children}</Container>
    </div>
  );
}

Section.propTypes = {
  color: StyleTypes.color,
  position: PropTypes.oneOf(['left', 'right']),
  pattern: PropTypes.bool,
  shorten: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Section.defaultProps = {
  color: null,
  position: null,
  pattern: false,
  shorten: false,
};

export default Section;
