import { useStaticQuery, graphql } from 'gatsby';

function useImage(image, fixed = false) {
  if (image.src) {
    const data = useStaticQuery(graphql`
      query allImages {
        sharpImages: allImageSharp {
          nodes {
            original {
              src
            }
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
            fixed(width: 1200, height: 1200) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
      }
    `);

    const sharpImages = data.sharpImages.nodes;
    const sharpImage = sharpImages.find(
      (item) => item.original.src === image.src,
    );

    if (!sharpImage) return image;

    return {
      sharpImage: {
        fixed: fixed ? sharpImage.fixed : undefined,
        fluid: !fixed ? sharpImage.fluid : undefined,
      },
    };
  }

  return image;
}

export default useImage;
