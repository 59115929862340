import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StyleTypes from 'styles/types';

import Icon from 'components/Icon';
import TransLink from 'components/TransLink';

import classes from './Button.scss';

function Button({
  className: classNameProp,
  as: Component,
  color,
  hoverColor,
  outline,
  size,
  icon,
  hover,
  spacing,
  children,
  ...props
}) {
  const className = classNames(classNameProp, {
    [classes.root]: true,
    [classes[color]]: true,
    [classes[`hover-${hoverColor}`]]: hoverColor,
    [classes.outline]: outline,
    [classes[size]]: true,
    [classes.hover]: hover,
    [classes['only-icon']]: !children && icon,
    [classes[`spacing-${spacing}`]]: spacing,
  });

  return (
    <Component className={className} {...props}>
      {icon && <Icon name={icon} />}
      {children}
    </Component>
  );
}

Button.propTypes = {
  className: PropTypes.string,
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  type: PropTypes.oneOf(['button', 'submit']),
  color: StyleTypes.themeColor,
  hoverColor: StyleTypes.themeColor,
  outline: PropTypes.bool,
  size: StyleTypes.size,
  icon: PropTypes.string,
  hover: PropTypes.bool,
  spacing: StyleTypes.size,
  children: PropTypes.node,
};

Button.defaultProps = {
  className: null,
  as: TransLink,
  type: 'button',
  color: 'primary',
  hoverColor: null,
  outline: false,
  size: 'md',
  hover: false,
  icon: null,
  spacing: null,
  children: null,
};

export default Button;
