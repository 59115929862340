import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StyleTypes from 'styles/types';

import classes from './Heading.scss';

function Heading({
  as: Component,
  className: classNameProp,
  color,
  size,
  weight,
  spacing,
  noMargin,
  children,
}) {
  const className = classNames(classNameProp, {
    [classes.root]: true,
    [classes[color]]: color,
    [classes[size]]: true,
    [classes[weight]]: true,
    [classes[`spacing-${spacing}`]]: spacing,
    [classes['no-margin']]: noMargin,
  });

  return <Component className={className}>{children}</Component>;
}

Heading.propTypes = {
  as: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span']),
  className: PropTypes.string,
  color: StyleTypes.color,
  size: StyleTypes.size,
  weight: StyleTypes.weight,
  spacing: StyleTypes.size,
  noMargin: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Heading.defaultProps = {
  as: 'span',
  className: null,
  color: 'primary',
  size: 'md',
  weight: 'bold',
  spacing: false,
  noMargin: false,
};

export default Heading;
