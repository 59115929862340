import React from 'react';
import PropTypes from 'prop-types';

import TransLink from 'components/TransLink';

import classes from './DropdownItem.scss';

function DropdownItem({ to, title, info, icon, children }) {
  return (
    <span className={classes.root}>
      <TransLink
        to={to}
        className={classes.link}
        activeClassName={classes.active}
      >
        {icon && <img className={classes.icon} src={icon} />}

        <span className={classes.content}>
          <span className={classes.title}>{title}</span>
          {info}
        </span>
      </TransLink>

      {children}
    </span>
  );
}

DropdownItem.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  info: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: PropTypes.node,
};

DropdownItem.defaultProps = {
  icon: null,
  children: null,
};

export default DropdownItem;
