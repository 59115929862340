// import chatIcon from 'assets/images/icons/chat.svg';
import gearsIcon from 'assets/images/icons/gears.svg';
import megafonIcon from 'assets/images/icons/megafon.svg';
import pencilIcon from 'assets/images/icons/pencil.svg';
import puzzleIcon from 'assets/images/icons/puzzle.svg';
import serverIcon from 'assets/images/icons/server.svg';
import slidersIcon from 'assets/images/icons/sliders.svg';
import wrenchIcon from 'assets/images/icons/wrench.svg';
import magentoIcon from 'assets/images/icons/magento.svg';
import codeIcon from 'assets/images/icons/code.svg';
import webshopIcon from 'assets/images/icons/webshop.svg';

export default [
  {
    title: 'Diensten',
    to: '/diensten',
    items: [
      {
        to: '/diensten/webshop-ontwerp',
        title: 'Webshop ontwerp',
        info: 'Ons ontwerp, jullie uitstraling',
        icon: webshopIcon,
        items: [
          {
            to: '/diensten/webshop-ontwerp/grafish-design',
            title: 'Grafisch design',
            info: 'Jouw merk perfect in beeld',
            icon: pencilIcon,
          },
          {
            to: '/diensten/webshop-ontwerp/frontend-design',
            title: 'Front-end design',
            info: 'Ontwerp vertaald op het scherm',
            icon: slidersIcon,
          },
        ],
      },
      {
        to: '/diensten/webshop-bouwen',
        title: 'Webshop bouwen',
        info: 'Gedreven ontwikkelaars',
        icon: gearsIcon,
        items: [
          {
            to: '/diensten/webshop-bouwen/development',
            title: 'Development',
            info: 'De beste code oplossingen',
            icon: codeIcon,
          },
          {
            to: '/diensten/webshop-bouwen/maatwerk',
            title: 'Maatwerk oplossingen',
            info: 'Toepassingen op maat',
            icon: puzzleIcon,
          },
          {
            to: '/diensten/webshop-bouwen/magento-webshop',
            title: 'Magento webshop',
            info: 'Passend bij jouw merk',
            icon: magentoIcon,
          },
        ],
      },
      {
        to: '/diensten/webshop-onderhoud',
        title: 'Webshop onderhoud',
        info: 'Ondersteuning en support',
        icon: wrenchIcon,
        items: [
          {
            to: '/diensten/webshop-onderhoud/magento-hosting',
            title: 'Magento Hosting',
            info: 'Snelle Magento hosting',
            icon: serverIcon,
          },
          {
            to: '/diensten/webshop-onderhoud/online-marketing',
            title: 'Online Marketing',
            info: 'SEO, SEA of social media',
            icon: megafonIcon,
          },
        ],
      },
    ],
  },
  {
    title: 'E-Commerce',
    to: '/ecommerce/b2b-modules',
    items: [
      {
        to: '/ecommerce/b2b-modules',
        title: 'B2B modules',
        info: 'De beste B2B oplossingen',
      },
      {
        to: '/ecommerce/b2b-ecommerce',
        title: 'B2B E-commerce',
        info: 'Spreek de taal van jouw klanten',
      },
      {
        to: '/ecommerce/b2c-ecommerce',
        title: 'B2C E-commerce',
        info: 'Focus op de eindgebruiker',
      },
    ],
  },
  {
    title: 'Ons werk',
    to: '/ons-werk',
  },
  {
    title: 'Over ons',
    to: '/over-ons/wie-zijn-wij',
    items: [
      {
        to: '/over-ons/wie-zijn-wij',
        title: 'Wie zijn wij?',
        info: 'Gedreven webshop bouwers',
      },
      {
        to: '/over-ons/ons-team',
        title: 'Ons Team',
        info: 'Maak kennis met de talenten',
      },
      {
        to: '/over-ons/naam-en-logo',
        title: 'Onze naam en ons logo',
        info: 'Met liefde gebouwd',
      },
    ],
  },
  {
    title: 'Vacatures',
    to: '/vacatures',
  },
  {
    title: 'Blog',
    to: '/blog',
  },
  {
    title: 'Contact',
    to: '/contact',
  },
];
