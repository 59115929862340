import { useLocation } from '@reach/router';
import { useStaticQuery, graphql } from 'gatsby';

function useSeo() {
  const { pathname: p } = useLocation();
  const filteredPath = p.length > 1 ? p.replace(/\/$/, '') : p;

  const data = useStaticQuery(graphql`
    query seo {
      allSeo {
        nodes {
          pathname
          title
          description
        }
      }
    }
  `);

  const items = data.allSeo.nodes;
  const seo = items.find((item) => item.pathname === filteredPath);

  return seo || {};
}

export default useSeo;
