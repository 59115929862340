import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Content.scss';

function Content({ align, wide, children }) {
  const className = classNames({
    [classes.root]: true,
    [classes[align]]: align,
    [classes.wide]: wide,
  });

  return <div className={className}>{children}</div>;
}

Content.propTypes = {
  align: PropTypes.oneOf(['left', 'right']),
  wide: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Content.defaultProps = {
  align: null,
  wide: false,
};

export default Content;
