import React from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import useImage from 'hooks/useImage';
import useSeo from 'hooks/useSeo';
import useSiteMetaData from 'hooks/useSiteMetaData';

function getImageUrl(image) {
  if (image.sharpImage) {
    const { fixed, fluid } = image.sharpImage;
    return fixed?.src || fluid.src;
  }

  return image.src || image.publicUrl;
}

function getCurrentUrl(siteUrl, pathname) {
  return siteUrl + pathname;
}

function getCanonicalUrl(siteUrl, canonicalUrlPath) {
  if (canonicalUrlPath) {
    return siteUrl + canonicalUrlPath;
  }

  return null;
}

function Seo({
  image: imageProp,
  title: titleProp,
  description: descriptionProp,
  canonicalUrlPath: canonicalUrlPathProp,
  noIndexNoFollow: noIndexNoFollowProp,
}) {
  const seo = useSeo();
  const siteMetaData = useSiteMetaData();
  const image = useImage(imageProp, true);
  const imageUrl = getImageUrl(image);
  const currentUrl = getCurrentUrl(siteMetaData.siteUrl, seo.pathname);
  const canonicalUrl = getCanonicalUrl(siteMetaData.siteUrl, canonicalUrlPathProp);
  let robotsMeta = siteMetaData.robots;

  if (noIndexNoFollowProp) {
    robotsMeta = 'noindex, nofollow';
  }

  const title = `Batao: ${titleProp || seo.title}`;
  const description = descriptionProp || seo.description;

  return (
    <Helmet defer={false}>
      <html lang="nl" />
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="robots" content={robotsMeta} />
      {canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
      {imageUrl && <meta name="image" content={imageUrl} />}

      {/* OpenGraph tags */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:locale" content={siteMetaData.ogLocale} />
      <meta property="og:type" content={siteMetaData.ogType} />
      <meta property="og:url" content={currentUrl} />
      {imageUrl && <meta property="og:image" content={imageUrl} />}

      {/* Twitter Card tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      {imageUrl && <meta name="twitter:image" content={imageUrl} />}
    </Helmet>
  );
}

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  canonicalUrlPath: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    sharpImage: PropTypes.shape({
      fixed: PropTypes.object, // eslint-disable-line react/forbid-prop-types
      fluid: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    }),
    publicURL: PropTypes.string,
  }).isRequired,
  noIndexNoFollow: PropTypes.bool,
};

Seo.defaultProps = {
  title: null,
  description: null,
  canonicalUrlPath: null,
  noIndexNoFollow: false,
};

export default Seo;
