import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Hamburger.scss';

function Line({ position }) {
  const className = classNames({
    [classes.line]: true,
    [classes[position]]: position,
  });

  return <span className={className} />;
}

Line.propTypes = {
  position: PropTypes.oneOf(['top', 'middle', 'bottom']).isRequired,
};

function Hamburger({ open, onClick }) {
  const className = classNames({
    [classes.root]: true,
    [classes.open]: open,
  });

  return (
    <button className={className} onClick={onClick}>
      <Line position="top" />
      <Line position="middle" />
      <Line position="middle" />
      <Line position="bottom" />
    </button>
  );
}

Hamburger.propTypes = {
  open: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default Hamburger;
