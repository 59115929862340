import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import StyleTypes from 'styles/types';

import TransLink from 'components/TransLink';

import classes from './Link.scss';

function Link({
  as: Component,
  color,
  className: classNameProp,
  children,
  ...props
}) {
  const className = classNames(classNameProp, classes.root, classes[color]);

  return (
    <Component className={className} {...props}>
      {children}
    </Component>
  );
}

Link.propTypes = {
  as: PropTypes.oneOfType([PropTypes.node, PropTypes.elementType]),
  color: StyleTypes.color,
  className: PropTypes.string,
  children: PropTypes.node,
};

Link.defaultProps = {
  as: TransLink,
  color: 'white',
  className: null,
  children: null,
};

export default Link;
