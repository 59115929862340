import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Dropdown.scss';

function Dropdown({ nested, children }) {
  const className = classNames({
    [classes.root]: true,
    [classes.nested]: nested,
  });

  return (
    <div className={className}>
      <div className={classes.inner}>{children}</div>
    </div>
  );
}

Dropdown.propTypes = {
  nested: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Dropdown.defaultProps = {
  nested: false,
};

export default Dropdown;
