import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MobileNavigation from 'components/MobileNavigation';
import Header from 'components/Header';
import Footer from 'components/Footer';

import classes from './Layout.scss';

function Layout({ transparentHeader, children }) {
  const [open, setOpen] = useState(false);

  const toggleOpen = () => setOpen(!open);

  const className = classNames({
    [classes.root]: true,
    [classes.open]: open,
  });

  return (
    <div className={className}>
      <MobileNavigation open={open} />

      <Header
        transparent={transparentHeader}
        open={open}
        toggleOpen={toggleOpen}
      />

      {children}

      <Footer />

      <div className={classes.frame} />
    </div>
  );
}

Layout.propTypes = {
  transparentHeader: PropTypes.bool,
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {
  transparentHeader: false,
};

export default Layout;
