import React from 'react';
import PropTypes from 'prop-types';

import Text from 'components/Text';

import classes from './Column.scss';

function Column({ title, children }) {
  return (
    <div className={classes.root}>
      <Text
        className={classes.heading}
        size="md"
        weight="semi"
        spacing="sm"
        color="gray-light"
      >
        {title}
      </Text>

      {children}
    </div>
  );
}

Column.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default Column;
