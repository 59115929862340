import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import logo from 'assets/images/logo.svg';

import Container from 'components/Container';
import Text from 'components/Text';
import Link from 'components/Link';
import Image from 'components/Image';

import classes from './Footer.scss';
import Column from './Column';
import Social from './Social';

import config from '../../config';

function Footer() {
  const data = useStaticQuery(graphql`
    query footerBlog {
      allBlog(limit: 6, sort: { fields: _created, order: DESC }) {
        nodes {
          _id
          slug
          title
        }
      }
    }
  `);

  const blogItems = data.allBlog.nodes;

  return (
    <div className={classes.root}>
      <Container className={classes.content}>
        <Column title="Contact Gegevens" hideTitleDesktop>
          <Image
            className={classes.logo}
            image={{ src: logo }}
            alt="Het logo van Batao"
          />

          <Text color="white">Wijchenseweg 111</Text>
          <Text color="white">6538 SW Nijmegen</Text>

          <Link as="a" color="white" href={`tel:${config.company.phone}`}>
            {config.company.phone}
          </Link>
          <Link as="a" color="white" href={`mailto:${config.company.email}`}>
            {config.company.email}
          </Link>

          <div className={classes.social}>
            {config.social.map((item, key) => (
              <Social key={key} name={item.icon} href={item.link} />
            ))}
          </div>
        </Column>

        <Column title="Diensten">
          <Link to="/diensten/webshop-bouwen/magento-webshop">
            Magento webshop
          </Link>
          <Link to="/diensten/webshop-onderhoud/magento-hosting">
            Magento hosting
          </Link>
          <Link to="/diensten/webshop-bouwen/maatwerk">
            Maatwerk oplossingen
          </Link>
          <Link to="/diensten/webshop-ontwerp/grafish-design">
            Grafish design
          </Link>
          <Link to="/diensten/webshop-ontwerp/frontend-design">
            Frontend design
          </Link>
          <Link to="/diensten/webshop-onderhoud/online-marketing">
            Online marketing
          </Link>
        </Column>

        <Column title="Batao">
          <Link to="/diensten">Diensten</Link>
          <Link to="/ecommerce/b2b-ecommerce">B2B E-Commerce</Link>
          <Link to="/ons-werk">Ons werk</Link>
          <Link to="/over-ons/wie-zijn-wij">Over ons</Link>
          <Link to="/vacatures">Vacatures</Link>
          <Link to="/contact">Contact</Link>
        </Column>

        <Column title="Blogs" hideTablet>
          {blogItems.map((item) => (
            <Link key={item._id} to={`/blog/${item.slug}`}>
              {item.title}
            </Link>
          ))}
        </Column>
      </Container>

      <Container className={classes.copyright}>
        <Text color="primary-lighter" size="sm">
          © Copyright {new Date().getFullYear()}&nbsp;
          <Text color="primary-lighter" size="sm" weight="semi">
            Batao e-commerce experts
          </Text>
        </Text>

        <div className={classes.links}>
          <Link to="/privacy/privacy-policy" color="primary-lighter">
            Privacy
          </Link>

          <Link to="/terms-and-conditions/website" color="primary-lighter">
            Algemene Voorwaarden
          </Link>
        </div>
      </Container>
    </div>
  );
}

export default Footer;
