import React from 'react';
import PropTypes from 'prop-types';

import TransLink from 'components/TransLink';

import classes from './Item.scss';

function Item({ title, badge, to, children, ...props }) {
  return (
    <span className={classes.root}>
      <TransLink
        className={classes.link}
        activeClassName={classes.active}
        partiallyActive
        to={to}
        {...props}
      >
        {title}

        {badge && <span className={classes.badge}>{badge}</span>}
      </TransLink>

      {children}
    </span>
  );
}

Item.propTypes = {
  to: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  badge: PropTypes.number,
  children: PropTypes.node,
};

Item.defaultProps = {
  badge: null,
  children: null,
};

export default Item;
